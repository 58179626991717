import { Tooltip, Typography, Checkbox, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { mergeClasses } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { collegeIdFn } from "../../../api/SignIn/selector";
import {
  CallCollegeAPI,
  CallCollegeInternalAPI,
} from "../../../api/University/APIs/action";
import { allCollegeSelectedSelectorFn } from "../../../api/University/APIs/selector";
import { Autocomplete } from "@material-ui/lab";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { styled } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";

const NowrapFormLabel = styled(FormLabel)({
  whiteSpace: "nowrap",
});

const icon = (
  <RadioButtonUncheckedIcon
    color="primary"
    fontSize="small"
    style={{ marginLeft: "-10px" }}
  />
);
const checkedIcon = (
  <RadioButtonCheckedIcon
    color="primary"
    fontSize="small"
    style={{ marginLeft: "-10px" }}
  />
);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    color: "#000",
  },
  drop: {
    padding: "5px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: "8px",
    width: "150px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CollegeGroupDropDown({ collegeList, disabled }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const allCollegeSelected = useSelector(allCollegeSelectedSelectorFn);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const collegeId = useSelector(collegeIdFn);

  const checkName = () => {
    var name = "";
    const collegeID = collegeId || localStorage.getItem("college_id");
    collegeList?.map((item) => {
      if (item?.id == collegeID) name = item?.name;
    });
    return name;
  };

  const user_details = useSelector((state) => state?.UserDetails?.user_details);

  const handleClickNew = (id) => {
    dispatch(
      CallCollegeAPI(
        id,
        alert,
        history,
        location,
        user_details,
        location.pathname
      )
    );
  };
  const handleSubmit = (id) => {
    dispatch(
      CallCollegeInternalAPI(
        id,
        alert,
        history,
        location,
        user_details,
        location.pathname
      )
    );
  };

  // useEffect(() => {
  //   if(collegeList){
  //     handleSubmit(collegeList[0]?.id);
  //   }
  // }, [])

  return (
    // <div>
    //   <Button
    //     className={mergeClasses.title}
    //     aria-controls="customized-menu"
    //     style={{
    //       display: "flex",
    //       borderRadius: "8px",
    //       backgroundColor: "#e2e6ea",
    //       color: "#7a7e83",
    //       boxShadow: "none",
    //       maxWidth: "200px",
    //     }}
    //     aria-haspopup="true"
    //     variant="contained"
    //     size="small"
    //     onClick={handleClick}
    //     disabled={disabled}
    //     endIcon={<ArrowDropDownIcon />}
    //   >
    //     <Typography
    //       variant="overline"
    //       color="inherit"
    //       style={{ marginRight: "20px", fontWeight: "bold" }}
    //       noWrap
    //     >
    //       {(collegeId || localStorage.getItem("college_id")) &&
    //       !location.pathname.includes("college-group")
    //         ? checkName()
    //         : "Select a College"}
    //     </Typography>
    //   </Button>
    //   <StyledMenu
    //     id="customized-menu"
    //     anchorEl={anchorEl}
    //     keepMounted
    //     open={Boolean(anchorEl)}
    //     onClose={handleClose}
    //   >
    //     {collegeList?.map((item, index) => {
    //       return (
    //         <StyledMenuItem
    //           selected={item?.id === collegeId ? true : false}
    //           onClick={() => {
    //             // setPlacementSession(item);
    //             // handleClose();
    //             // if (show) setFilterDataText("No Data Found");
    //             if (!location.pathname.includes("college-group"))
    //               handleClickNew(item?.id);
    //             else handleSubmit(item?.id);
    //             handleClose();
    //           }}
    //         >
    //           <Tooltip title={item.name} placement="right">
    //             <Typography variant="overline" color="inherit" noWrap>
    //               {item.name}
    //             </Typography>
    //           </Tooltip>
    //         </StyledMenuItem>
    //       );
    //     })}
    //   </StyledMenu>
    // </div>

    <>
      <Autocomplete
        options={collegeList}
        getOptionLabel={(option) => option.name}
        PaperProps={{
          style: {
            backgroundColor: "#f0f0f0", // Change background color
            borderRadius: 8, // Change border radius
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Add a shadow
            fontSize: "12px",
          },
        }}
        onChange={(event, newValue) => {
          if (!location.pathname.includes("educational-group")) {
            handleClickNew(newValue.id);
          } else {
            handleSubmit(newValue.id);
          }
        }}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{
                marginRight: 8,
                "&:hover": {
                  backgroundColor: "none",
                },
              }}
              checked={option?.id == collegeId ? true : false}
            />

            <span style={{ fontSize: "12px" }}> {option.name}</span>
          </React.Fragment>
        )}
        style={{ width: 200 }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            InputLabelProps={{
              style: { fontSize: "12px" },
            }}
            fullWidth
            InputProps={{
              ...params.InputProps,
              style: { fontSize: "12px" },
            }}
            label={
              (collegeId || localStorage.getItem("college_id")) &&
              !location.pathname.includes("educational-group") ? (
                <span className="customtextlabel">{checkName()}</span>
              ) : (
                "Select a College"
              )
            }
            FormLabelProps={{
              component: NowrapFormLabel,
            }}
          />
        )}
      />
    </>
  );
}
