import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Suspense } from "react";
// import AllColleges from "./AllColleges";
// import RightDropDown from "./RightDropDown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect, useState } from "react";

function AllCollegesGrid({
  collegesList,
  setCollegesList,
  loadingFromData,
  setSearchState,
  setSelectedCollegeIds,
  nestedColleges,
  groups,
  setGroups,
  searchQuery,
}) {
  const AllColleges = React.lazy(() => import("./AllColleges"));
  const RightDropDown = React.lazy(() => import("./RightDropDown"));

  const handleSelectAll = (event, group, collegeIds) => {
    const isChecked = event.target.checked;

    // Update selected college IDs for multiple colleges
    setSelectedCollegeIds((prevSelected) => {
      if (isChecked) {
        // Add all college IDs to the selected list that aren't already there
        const newSelected = collegeIds.filter(
          (id) => !prevSelected.includes(id)
        );
        return [...prevSelected, ...newSelected];
      } else {
        // Remove all specified college IDs from the selected list
        return prevSelected.filter(
          (collegeId) => !collegeIds.includes(collegeId)
        );
      }
    });

    // Update collegesList for multiple colleges
    setCollegesList((prev) => {
      const newCollegesList = prev.map((college) => {
        if (collegeIds.includes(college.id)) {
          return {
            ...college,
            checked: isChecked,
            placementCycles: college.placementCycles.map((cycle) => ({
              ...cycle,
              checked: isChecked,
            })),
          };
        }
        return college;
      });
      return newCollegesList;
    });

    // Update searchState for multiple colleges
    setSearchState((prevSearchState) => {
      const newSearchState = prevSearchState.map((college) => {
        if (collegeIds.includes(college.id)) {
          return {
            ...college,
            checked: isChecked,
            placementCycles: college.placementCycles.map((cycle) => ({
              ...cycle,
              checked: isChecked,
            })),
          };
        }
        return college;
      });
      return newSearchState;
    });

    // const checked = collegesList
    //   ?.filter((college) => {
    //     return group?.colleges.includes(college.id);
    //   })
    //   ?.every((college) => college.checked === true);
    // setGroups((prevGroups) =>
    //   prevGroups.map((g) =>
    //     g.id === group.id ? { ...g, selected: checked } : g
    //   )
    // );
  };

  const checkValue = (group) => {
    // console.log("group", group);
    // if (searchQuery != "") {
    //   return group?.selected || false;
    // }
    if (!collegesList?.length || !group?.colleges?.length) return false;
    if (
      !group.colleges.every((groupCollegeId) =>
        collegesList.some((college) => college.id === groupCollegeId)
      )
    ) {
      return false;
    }

    return collegesList
      ?.filter((college) => {
        return group?.colleges.includes(college.id);
      })
      ?.every((college) => college.checked === true);
  };
  const updateGroupSelected = () => {
    setGroups((prevGroups) =>
      prevGroups.map((g) => {
        const groupColleges = collegesList
          ?.filter((college) => g.colleges.includes(college.id))
          ?.map((college) => college.checked);

        const allChecked =
          groupColleges.length > 0 &&
          groupColleges.every((checked) => checked === true);

        return { ...g, selected: allChecked };
      })
    );
  };

  useEffect(() => {
    updateGroupSelected();
  }, [collegesList]);
  const [expandedGroup, setExpandedGroup] = useState(null);

  return (
    <Box>
      <Grid container spacing={0} style={{ margin: 0, width: "100%" }}>
        {nestedColleges ? (
          <Grid item sm={12}>
            {groups?.map(
              (groups, index) =>
                collegesList?.some((college) =>
                  groups?.colleges.includes(college.id)
                ) && (
                  <>
                    <Accordion
                      expanded={expandedGroup === groups?.group_id}
                      style={{ boxShadow: "none" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                        onClick={() =>
                          setExpandedGroup(
                            expandedGroup === groups.group_id
                              ? null
                              : groups.group_id
                          )
                        }
                      >
                        <FormControlLabel
                          aria-label="Acknowledge"
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                          control={
                            <Checkbox
                              color="primary"
                              checked={checkValue(groups)}
                              disabled={searchQuery != ""}
                              onClick={(event) =>
                                handleSelectAll(event, groups, groups?.colleges)
                              }
                            />
                          }
                          label={groups?.group_name}
                        />
                      </AccordionSummary>
                      {expandedGroup === groups.group_id && (
                        <AccordionDetails style={{ padding: 0 }}>
                          <div style={{ width: "100%" }}>
                            {collegesList
                              ?.filter((college) => {
                                return groups?.colleges.includes(college.id);
                              })
                              ?.map((college, index) => (
                                <Grid
                                  container
                                  spacing={0}
                                  style={{ margin: 0, width: "100%" }}
                                >
                                  {!loadingFromData ? (
                                    <Grid
                                      item
                                      md={6}
                                      style={{
                                        borderTop: "1px solid #E0E0E0",
                                        borderBottom: "1px solid #E0E0E0",
                                        borderRight: "1px solid #E0E0E0",
                                        padding: "10px ",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      <Suspense
                                        fallback={
                                          <Skeleton width={560} height={108} />
                                        }
                                      >
                                        <AllColleges
                                          id={college?.id}
                                          details={college?.details}
                                          disable={
                                            college?.details?.is_job_applied
                                          }
                                          checked={college?.checked}
                                          setCollegesList={setCollegesList}
                                          setSearchState={setSearchState}
                                          setSelectedCollegeIds={
                                            setSelectedCollegeIds
                                          }
                                        />
                                      </Suspense>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      md={6}
                                      style={{
                                        borderTop: "1px solid #E0E0E0",
                                        borderBottom: "1px solid #E0E0E0",
                                        borderRight: "1px solid #E0E0E0",
                                        padding: "10px ",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      <Skeleton
                                        variant="rect"
                                        width={560}
                                        height={108}
                                        style={{
                                          borderTopRightRadius: "5px",
                                          borderTopLeftRadius: "5px",
                                          marginLeft: 20,
                                        }}
                                      />
                                    </Grid>
                                  )}
                                  {!loadingFromData ? (
                                    <Grid
                                      item
                                      md={6}
                                      style={{
                                        borderTop: "1px solid #E0E0E0",
                                        borderBottom: "1px solid #E0E0E0",
                                        padding: "10px ",
                                        paddingRight: "20px",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          height: "100%",
                                          padding: "0px 20px 0px 20px",
                                        }}
                                      >
                                        <Suspense
                                          fallback={
                                            <Skeleton
                                              width={560}
                                              height={108}
                                            />
                                          }
                                        >
                                          <RightDropDown
                                            index={index}
                                            placementCycles={
                                              college?.placementCycles
                                            }
                                            setCollegesList={setCollegesList}
                                            error={college?.error}
                                            // disable={!college?.checked}
                                            setSearchState={setSearchState}
                                            disable={
                                              college?.details
                                                ?.is_job_applied ||
                                              !college?.checked
                                            }
                                          />
                                        </Suspense>
                                      </Box>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      md={6}
                                      style={{
                                        borderTop: "1px solid #E0E0E0",
                                        borderBottom: "1px solid #E0E0E0",
                                        borderRight: "1px solid #E0E0E0",
                                        padding: "10px ",
                                        paddingRight: "20px",
                                      }}
                                    >
                                      <Skeleton
                                        variant="rect"
                                        width={560}
                                        height={108}
                                        style={{
                                          borderTopRightRadius: "5px",
                                          borderTopLeftRadius: "5px",
                                          marginLeft: 20,
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              ))}
                          </div>
                        </AccordionDetails>
                      )}
                    </Accordion>
                    {/* <h1>{groups?.group_name}</h1>
                {collegesList
                  ?.filter((college) => {
                    return groups?.colleges.includes(college.id);
                  })
                  ?.map((college, index) => (
                    <Grid
                      container
                      spacing={2}
                      style={{ margin: 0, width: "100%" }}
                    >
                      {!loadingFromData ? (
                        <Grid
                          item
                          md={6}
                          style={{
                            borderTop: "1px solid #E0E0E0",
                            borderBottom: "1px solid #E0E0E0",
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <AllColleges
                            id={college?.id}
                            details={college?.details}
                            disable={college?.details?.is_job_applied}
                            checked={college?.checked}
                            setCollegesList={setCollegesList}
                            setSearchState={setSearchState}
                            setSelectedCollegeIds={setSelectedCollegeIds}
                          />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          md={6}
                          style={{
                            borderTop: "1px solid #E0E0E0",
                            borderBottom: "1px solid #E0E0E0",
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <Skeleton
                            variant="rect"
                            width={560}
                            height={108}
                            style={{
                              borderTopRightRadius: "5px",
                              borderTopLeftRadius: "5px",
                              marginLeft: 20,
                            }}
                          />
                        </Grid>
                      )}
                      {!loadingFromData ? (
                        <Grid
                          item
                          md={6}
                          style={{
                            borderTop: "1px solid #E0E0E0",
                            borderBottom: "1px solid #E0E0E0",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "100%",
                              padding: "0px 20px 0px 20px",
                            }}
                          >
                            <RightDropDown
                              index={index}
                              placementCycles={college?.placementCycles}
                              setCollegesList={setCollegesList}
                              error={college?.error}
                              // disable={!college?.checked}
                              setSearchState={setSearchState}
                              disable={
                                college?.details?.is_job_applied ||
                                !college?.checked
                              }
                            />
                          </Box>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          md={6}
                          style={{
                            borderTop: "1px solid #E0E0E0",
                            borderBottom: "1px solid #E0E0E0",
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <Skeleton
                            variant="rect"
                            width={560}
                            height={108}
                            style={{
                              borderTopRightRadius: "5px",
                              borderTopLeftRadius: "5px",
                              marginLeft: 20,
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ))} */}
                  </>
                )
            )}
          </Grid>
        ) : (
          collegesList?.map((college, index) => (
            <>
              {!loadingFromData ? (
                <Grid
                  item
                  md={6}
                  style={{
                    borderBottom: "1px solid #E0E0E0",
                    borderRight: "1px solid #E0E0E0",
                  }}
                >
                  <Suspense fallback={<Skeleton width={560} height={108} />}>
                    <AllColleges
                      id={college?.id}
                      details={college?.details}
                      disable={college?.details?.is_job_applied}
                      checked={college?.checked}
                      setCollegesList={setCollegesList}
                      setSearchState={setSearchState}
                      setSelectedCollegeIds={setSelectedCollegeIds}
                    />
                  </Suspense>
                </Grid>
              ) : (
                <Grid
                  item
                  md={6}
                  style={{
                    borderBottom: "1px solid #E0E0E0",
                    borderRight: "1px solid #E0E0E0",
                  }}
                >
                  <Skeleton
                    variant="rect"
                    width={560}
                    height={108}
                    style={{
                      borderTopRightRadius: "5px",
                      borderTopLeftRadius: "5px",
                      marginLeft: 20,
                    }}
                  />
                </Grid>
              )}
              {!loadingFromData ? (
                <Grid item md={6} style={{ borderBottom: "1px solid #E0E0E0" }}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      padding: "0px 20px 0px 20px",
                    }}
                  >
                    <Suspense fallback={<Skeleton width={560} height={108} />}>
                      <RightDropDown
                        index={index}
                        placementCycles={college?.placementCycles}
                        setCollegesList={setCollegesList}
                        error={college?.error}
                        // disable={!college?.checked}
                        setSearchState={setSearchState}
                        disable={
                          college?.details?.is_job_applied || !college?.checked
                        }
                      />
                    </Suspense>
                  </Box>
                </Grid>
              ) : (
                <Grid
                  item
                  md={6}
                  style={{
                    borderBottom: "1px solid #E0E0E0",
                    borderRight: "1px solid #E0E0E0",
                  }}
                >
                  <Skeleton
                    variant="rect"
                    width={560}
                    height={108}
                    style={{
                      borderTopRightRadius: "5px",
                      borderTopLeftRadius: "5px",
                      marginLeft: 20,
                    }}
                  />
                </Grid>
              )}
            </>
          ))
        )}
      </Grid>
    </Box>
  );
}

export default AllCollegesGrid;
