import { Breadcrumbs, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PlacementAccordingToJob } from "../../api/JobPost/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  isCollegeGroupFn,
} from "../../api/SignIn/selector";
import TrackHeader from "../../components/Header/TrackHeader";
import TrackJobTab from "../../components/Header/TrackJobTab";
import DropDownButton from "../../components/Input/DropDownButton/trackPlacement";
import { BackendBaseApi, getColumnString } from "../../constants/constants";
import { checkForToken, sendExportLink } from "../../constants/ApiCall";
import { sanchalanAxiosGetReq } from "../../api/BaseApi/apiBase";
import TopDropDown from "./TopDropdown";
import { checkForBreadcrumb } from "../../utility/checkBreadcrumbs";
import { isEducationGroupFn } from "../../api/Domain/selector";

export default function Track() {
  const location = useLocation();
  const initialFilterState = {
    job_id: null,
    current_status: null,
    search: null,
    search_check: false,
    enrollment_search: null,
    enrollment_search_check: false,
    course: null,
    degree: null,
    batch: null,
    applied_on: null,
    filter_college_id: null,
    navbar_object: { tooltip: "Applied" },
  };
  const [filters, setFilters] = useState(initialFilterState);

  const [moveToNext, setMoveToNext] = useState({
    round_id: null,
    feedback: null,
    feedback_typed: "",
    round_name: null,
    ctc: null,
  });

  const [navbarData, setNavbarData] = useState();
  const [breadcrumbData, setBreadCrumbData] = useState();
  const [trackTableData, setTrackTableData] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstApiCall, setFirstApiCall] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const userId = collegeDetails?.user_id;
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const [placementSession, setPlacementSession] = useState(null);
  const collegeId = useSelector(collegeIdFn);

  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);

  useEffect(() => {
    if (location?.search) {
      var search = location.search;
      var params = new URLSearchParams(search);
      var job_id = params.get("job_id");
      setFilters({ ...filters, job_id: job_id });
    }
  }, [location]);

  useEffect(() => {
    var search = location.search;
    var params = new URLSearchParams(search);
    var job_id = params.get("job_id");
    if (job_id && collegeDetails?.college_id)
      dispatch(
        PlacementAccordingToJob(
          alert,
          history,
          location,
          job_id,
          collegeDetails?.college_id
        )
      );
  }, [collegeDetails, filters?.job_id]);

  const placementSessionList = useSelector(
    (state) => state?.JobPlacement?.placementList
  );

  const checkCurrentTab = () => {
    if (filters?.current_status) return `&round_id=${filters?.current_status}`;
    else return ``;
  };

  const checkSearch = () => {
    if (filters?.search) return `&search=${filters?.search}`;
    else return ``;
  };

  const checkEnrollmentSearch = () => {
    if (filters?.enrollment_search)
      return `&enrollment_no=${filters?.enrollment_search}`;
    else return ``;
  };

  const checkDegree = () => {
    if (filters?.degree) return `&degree=${filters?.degree?.id}`;
    else return ``;
  };

  const checkCourse = () => {
    if (filters?.course) return `&course=${filters?.course?.id}`;
    else return ``;
  };

  const checkBatch = () => {
    if (filters?.batch) return `&batch=${filters?.batch.id}`;
    else return ``;
  };

  const checkCollege = () => {
    if (filters?.filter_college_id)
      return `&filter_college_id=${filters?.filter_college_id.id}`;
    else return ``;
  };

  const checkPlacement = () => {
    if (placementSession?.id && placementSession.id !== "All")
      return `&placement_session=${placementSession.id}`;
    else return ``;
  };

  const checkGroup = () => {
    if (selectedGroup?.id && selectedGroup.id !== "All")
      return `&group_id=${selectedGroup.id}`;
    else return ``;
  };

  const checkAppliedDate = () => {
    if (filters?.applied_on)
      return `&applied_on=${new Date(filters.applied_on)
        .toISOString()
        .substring(0, 10)}`;
    else return ``;
  };

  useEffect(() => {
    if (filters?.job_id) {
      trackHeader(true);
    }
  }, [filters?.job_id]);

  useEffect(() => {
    if (filters?.job_id && filters?.current_status) {
      trackTable();
    }
  }, [filters?.job_id, filters?.current_status]);

  useEffect(() => {
    if (filters?.search_check === true) {
      trackTable();
      trackHeader();
    }
  }, [filters?.search_check]);

  useEffect(() => {
    if (filters?.enrollment_search_check === true) {
      trackTable();
      trackHeader();
    }
  }, [filters?.enrollment_search_check]);

  useEffect(() => {
    if (firstApiCall) {
      trackTable();
      trackHeader();
    }
  }, [
    filters?.degree,
    filters?.course,
    filters?.batch,
    filters?.applied_on,
    filters?.filter_college_id,
  ]);

  const trackHeader = (call) => {
    axios
      .get(
        `${
          BackendBaseApi.SANCHALAN
        }api/manage_job/track_applicant_header/?job_id=${
          filters?.job_id
        }${checkEnrollmentSearch()}${checkSearch()}${checkDegree()}${checkCourse()}${checkBatch()}${checkAppliedDate()}${checkPlacement()}${checkGroup()}${checkCollege()}`,
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          // for arranging the tabs in order as per passed array list from backend.

          const tabObject = res?.data?.data?.navbar_data;
          const keyOrder = res?.data?.data?.header_order;
          const reorderedTabs = {};

          keyOrder.forEach((key) => {
            if (tabObject.hasOwnProperty(key)) {
              const value = tabObject[key];
              reorderedTabs[key] = value;
            }
          });

          setNavbarData(reorderedTabs);
          setBreadCrumbData(res.data.data.breadcrumb_data);

          if (call)
            setFilters({
              ...filters,
              current_status:
                res?.data?.data?.navbar_data[keyOrder[0]].round_id,
            });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const trackTable = () => {
    if (!filters.current_status) return;
    setLoading(true);
    axios
      .get(
        `${
          BackendBaseApi.SANCHALAN
        }api/manage_job/track_applicant_main/?job_id=${
          filters?.job_id
        }${checkCurrentTab()}${checkEnrollmentSearch()}${checkSearch()}${checkDegree()}${checkCourse()}${checkBatch()}${checkAppliedDate()}${checkPlacement()}${checkGroup()}${checkCollege()}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          res.data.data.applicant_data.forEach((item) => {
            item["id"] = item.student_id;
          });
          setFilterList(res.data.data.filter_data);
          setTrackTableData(res.data.data.applicant_data);
          setLoading(false);
          setFilters({
            ...filters,
            search_check: false,
            enrollment_search_check: false,
          });
          setFirstApiCall(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        throw err;
      });
  };

  const [moveNextArray, setMoveNextArray] = useState([]);

  useEffect(() => {
    if (navbarData) {
      var arr = Object.keys(navbarData);
      var new_arr = [];
      var index;
      Object.keys(navbarData).map((item) => {
        if (navbarData[item].round_id === filters?.current_status) {
          if (navbarData[item].round_id !== "618f96f84ded0fcb55962c9f")
            index = Object.keys(navbarData).indexOf(item);
          else index = -1;
        }
      });
      if (index !== -1) arr.splice(0, index + 1);
      else arr.splice(1, 1);

      arr.map((item) => {
        if (item !== "rejected") {
          new_arr.push({
            name: navbarData[item].heading,
            round_id: navbarData[item].round_id,
          });
        }
      });
      setMoveNextArray(new_arr);
    }
  }, [navbarData, filters?.current_status]);

  const MoveToNextStep = (
    setLoadingInt,
    fromDialog,
    student_id,
    roundData,
    feedbackData = {}
  ) => {
    setLoadingInt(true);
    var arr = [];
    if (fromDialog) arr.push(student_id);
    else selectedRowsData?.map((item) => arr.push(item.id));
    if (!fromDialog) {
      var data = {
        applicant_feedback: {
          user_id: userId,
          round_id: moveToNext?.round_id,
          offered_ctc: moveToNext?.ctc,
          feedback_id: null,
          feedback_name:
            moveToNext?.feedback &&
            moveToNext?.feedback?.name.toLowerCase() === "other"
              ? moveToNext?.feedback_typed
              : moveToNext?.feedback?.name,
          round_name: moveToNext?.round_name,
          user_name:
            collegeDetails?.first_name + " " + collegeDetails?.last_name,
          posted_on: new Date().toISOString().slice(0, 10),
        },
        student_data: arr,
        job_id: filters?.job_id,
      };
    } else {
      var data = {
        applicant_feedback: {
          user_id: userId,
          round_id: roundData?.round_id,
          feedback_id: null,
          feedback_name:
            feedbackData?.feedback &&
            feedbackData?.feedback?.name.toLowerCase() === "other"
              ? feedbackData?.feedback_typed
              : feedbackData?.feedback?.name,
          round_name: roundData?.round_name,
          user_name:
            collegeDetails?.first_name + " " + collegeDetails?.last_name,
          posted_on: new Date().toISOString().slice(0, 10),
          offered_ctc: moveToNext?.ctc,
        },
        student_data: arr,
        job_id: filters?.job_id,
      };
    }
    axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_job/track_applicant_move/`,
        data,
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          setLoadingInt(false);
          var myArray = [];
          let temp = trackTableData.filter((data) =>
            arr.includes(data.student_id)
          );
          myArray = trackTableData.filter((item) => !temp.includes(item));
          setTrackTableData(myArray);
          setMoveToNext({
            ...moveToNext,
            round_id: "",
            feedback: null,
            feedback_typed: "",
            round_name: "",
            ctc: "",
          });
          trackTable();
          trackHeader();
          setSelectedRowsData([]);
        } else {
          alert.error("Some error Occurred");
          setLoadingInt(false);
        }
      })
      .catch((err) => {
        setLoadingInt(false);

        throw err;
      });
  };

  useEffect(() => {
    if (placementSessionList?.length > 0) {
      if (location.search.includes("placement_session")) {
        var search = location.search;
        var params = new URLSearchParams(search);
        var placement_id = params.get("placement_session");
        var placement_name = checkPlacementName(placement_id);
        setPlacementSession({
          id: placement_id,
          name: placement_name,
        });
      } else {
        setPlacementSession({ id: "All", name: "All Placement Cycles" });
      }
    }
  }, [location, placementSessionList?.length]);

  const checkPlacementName = (id) => {
    var name = "";
    placementSessionList.map((item) => {
      if (item.id === id) name = item.name;
    });
    return name;
  };

  useEffect(() => {
    // if (placementSession && placementSession.id !== "All") window.history.pushState({}, null, `/track/?job_id=${filters?.job_id}${checkPlacement()}`);
    // else window.history.pushState({}, null, `/track/?job_id=${filters?.job_id}`);
    if (filters?.job_id) {
      trackTable();
      trackHeader();
    }
  }, [placementSession, filters?.job_id]);

  const [tempExportList, setTempExportList] = useState([]);

  useEffect(() => {
    if (trackTableData?.length > 0) {
      var arr = [...trackTableData];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        new_arr.push({
          "Enrollment Id": item?.enrollment_id,
          Name: item?.name,
          Email: item?.email,
          "Contact Number": item?.phone,
          Degree: item?.degree_name,
          Course: item?.course_name,
          Batch: item?.batch,
          "Applied On": item?.applied_on,
          Feedback: item?.feedback_list.map(
            (feedbackObj) =>
              `Name: ${feedbackObj.user_name}\n Round: ${feedbackObj.round_name}\n Feedback: ${feedbackObj.feedback_name}\n\n`
          ),
        });
      });
      setTempExportList(new_arr);
    }
  }, [trackTableData?.length]);

  useEffect(() => {
    if (selectedRowsData?.length > 0) {
      let arr = [...selectedRowsData];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        new_arr.push({
          "Enrollment Id": item?.enrollment_id,
          Name: item?.name,
          Email: item?.email,
          "Contact Number": item?.phone,
          Degree: item?.degree_name,
          Course: item?.course_name,
          Batch: item?.batch,
          "Applied On": item?.applied_on,
          Feedback: item?.feedback_list.map(
            (feedbackObj) =>
              `Name: ${feedbackObj.user_name}\n Round: ${feedbackObj.round_name}\n Feedback: ${feedbackObj.feedback_name}\n\n`
          ),
        });
      });
      setTempExportList(new_arr);
    } else {
      if (trackTableData?.length > 0) {
        var arr = [...trackTableData];
        let new_arr = [];
        var obj = {};
        arr.forEach((item) => {
          new_arr.push({
            "Enrollment Id": item?.enrollment_id,
            Name: item?.name,
            Email: item?.email,
            "Contact Number": item?.phone,
            Degree: item?.degree_name,
            Course: item?.course_name,
            Batch: item?.batch,
            "Applied On": item?.applied_on,
            Feedback: item?.feedback_list.map(
              (feedbackObj) =>
                `Name: ${feedbackObj.user_name}\n Round: ${feedbackObj.round_name}\n Feedback: ${feedbackObj.feedback_name}\n\n`
            ),
          });
        });
        setTempExportList(new_arr);
      }
    }
  }, [selectedRowsData?.length]);

  const [selectedColumnFields, setSelectedColumnFields] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  const handleExport = () => {
    if (selectedColumnFields?.length > 0) {
      let columnStrings = getColumnString(selectedColumnFields);
      const URL = `api/manage_job/export-manage-job-track/?college_id=${
        collegeDetails?.college_id
      }&column_name=${columnStrings}&job_id=${
        filters?.job_id
      }${checkCurrentTab()}${checkEnrollmentSearch()}${checkSearch()}${checkDegree()}${checkCourse()}${checkBatch()}${checkAppliedDate()}${checkPlacement()}`;

      sendExportLink(URL, setExportLoading, alert, history, location, false);
    } else {
      alert.error("Select Columns to Export");
    }
  };

  // Educational Group / College Group Changes
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    if (filters?.job_id) {
      trackTable();
      trackHeader();
    }
  }, [selectedGroup]);

  return (
    <>
      {/* <Layout> */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to={checkForBreadcrumb(
            isEducationGroup,
            isCollegeGroup,
            collegeId,
            "manage-jobs"
          )}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" variant="body2">
            Jobs
          </Typography>
        </Link>
        <Link
          to={`/job/${breadcrumbData?.job_title
            .toLowerCase()
            .replace(" ", "-")}-${filters?.job_id}`}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" variant="body2">
            {breadcrumbData?.job_title}
          </Typography>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          color="inherit"
          href="/getting-started/installation/"
        >
          <Typography color="secondary" variant="body2">
            Track Jobs
          </Typography>
        </Link>
      </Breadcrumbs>
      {/* {placementSessionList?.length > 0 && (
        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <DropDownButton
            title="Placement Session 2021"
            placementSession={placementSession}
            setPlacementSession={setPlacementSession}
            placementSessionList={placementSessionList}
          />
        </div>
      )} */}
      <TopDropDown
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        collegeId={collegeDetails?.college_id}
        placementSession={placementSession}
        setPlacementSession={setPlacementSession}
      />
      <TrackHeader
        data={breadcrumbData}
        tempExportList={tempExportList}
        filters={filters}
        fromPublicTrack={false}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      <TrackJobTab
        navbarData={navbarData}
        filters={filters}
        setFilters={setFilters}
        filterList={filterList}
        trackTableData={trackTableData}
        setTrackTableData={setTrackTableData}
        selectedRowsData={selectedRowsData}
        setSelectedRowsData={setSelectedRowsData}
        loading={loading}
        moveNextArray={moveNextArray}
        moveToNext={moveToNext}
        setMoveToNext={setMoveToNext}
        MoveToNextStep={MoveToNextStep}
        fromPublicTrack={false}
        data={breadcrumbData}
        selectedColumnFields={selectedColumnFields}
        setSelectedColumnFields={setSelectedColumnFields}
      />
      {/* <TrackSuccessfully />
        <InterviewScheduling /> */}
      {/* <Interview /> */}
      {/* </Layout> */}
    </>
  );
}
